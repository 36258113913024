@font-face {
  font-family: Boing;
  src: url('./fonts/Boing-RegularWEB.woff'),
    url('./fonts/Boing-MediumWEB.woff') format('woff');
}

* {
  font-family: Boing;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f5f5f5;
}
